import { Context } from '@nuxt/types';

/**
 * This is a middleware function that will redirect any path that does not end with a slash to the same path with a slash.
 * it is only used during local development. Our AWS stack is configured to do this automatically.
 */
export default function redirects({ route, redirect }: Context) {
    if (route.path.length > 1 && !route.path.endsWith('/')) {
        return redirect({ path: `${route.path}/`, replace: true });
    }
    return true;
}
