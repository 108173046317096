import { PageBlokInterface, PageStoryInterface } from '~/types/content';
import { StoryblokBlokInterface } from '~/types/storyblok';

import PageBlokClass from '~/classes/PageBlokClass';
import StoryblokBlokClass from '~/classes/StoryblokBlokClass';

export default class StoryblokStoryClass implements PageStoryInterface {
    id: number;

    name: string;

    content: PageBlokInterface;

    blok: StoryblokBlokInterface;

    first_published_at: string;

    published_at: string;

    constructor() {
        this.id = 0;
        this.name = '';
        this.content = new PageBlokClass();
        this.blok = new StoryblokBlokClass();
        this.first_published_at = '';
        this.published_at = '';
    }
}
