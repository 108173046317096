// Based on https://github.com/storyblok/storyblok-nuxt-2/blob/main/lib/module/plugin.mjs

import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import 'isomorphic-fetch';
import Vue from 'vue';

import { StoryblokVue, useStoryblokApi, useStoryblokBridge, apiPlugin } from '@storyblok/vue-2';
import { Route } from 'vue-router';
import { StoryblokResponseInterface } from '~/types/storyblok';
import { PageStoryInterface } from '~/types/content';
import StoryblokStoryClass from '~/classes/StoryblokStoryClass';

export default defineNuxtPlugin(({ $config }, inject) => {
    Vue.use(StoryblokVue, {
        accessToken: $config.STORYBLOK_ACCESS_TOKEN,
        bridge: true,
        apiOptions: {
            cache: {
                type: $config.NODE_ENV === 'development' ? 'none' : 'memory',
            },
            region: 'us',
        },
        use: [apiPlugin],
    });

    const api = useStoryblokApi();

    /**
     * Loads the story data from the given URL with the given relations, if any, resolved.
     * @param url The Storyblok story path to load, like 'cdn/stories/home'.
     * @param throwNuxtError The Nuxt error function provided to asyncData().
     * @param relationsToResolve An array of '{BlockName}.{fieldName}' strings.
     * @param route The route object from nuxt context
     * @returns The story data.
     */
    const loadPage = async (
        url: string,
        throwNuxtError: Function,
        relationsToResolve: string[] = [],
        route = <Route>{},
    ) => {
        const isPreview = route.query.preview;
        let story: PageStoryInterface = new StoryblokStoryClass();
        let response: StoryblokResponseInterface = {
            story: '',
            rels: [],
        };

        try {
            const { data } = await api.get(url, {
                version: isPreview ? 'draft' : 'published',
                resolve_relations: relationsToResolve,
                resolve_links: 'url',
            });

            response = data;
        } catch (e: any) {
            // On an error from API present user nuxt error page
            // https://nuxtjs.org/docs/internals-glossary/context/#error
            throwNuxtError(e);
        }

        story = response.story;

        return story;
    };

    const fetchDataSource = async (datasource: string) => {
        let response;
        try {
            const { data } = await api.get('cdn/datasource_entries', {
                datasource,
            });
            response = data;
        } catch (e: any) {
            // eslint-disable-next-line no-console
            console.error(e);
        }

        const datasourceEntries = response?.datasource_entries;

        const datasourceObject = datasourceEntries.reduce((obj: Record<string, any>, item: any) => {
            // Assuming each item has a unique 'name' property that we can use to form the keys of the new object
            // eslint-disable-next-line no-param-reassign
            obj[item.name] = item.value;
            return obj;
        }, {});

        return datasourceObject;
    };

    inject('storyblok', {
        api,
        useStoryblokBridge,
        loadPage,
        fetchDataSource,
    });
});
