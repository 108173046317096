import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { datadogRum } from '@datadog/browser-rum';

// https://app.datadoghq.com/rum/application/a3b23589-e9d3-4321-9a52-8c7a30f3fb22
export default defineNuxtPlugin(({ isDev, $config }) => {
    if (isDev || !process.client) return;

    datadogRum.init({
        applicationId: 'f16d3986-8f52-471e-bafa-6ae807d88a6c',
        clientToken: 'pub09e69f091bf0431d0983f34121f9eeea',
        site: 'datadoghq.com',
        service: 'es-cms-articles',
        env: $config.DEPLOY_ENV,
        version: $config.VERSION,
        sampleRate: 100,
        premiumSampleRate: 100,
        trackInteractions: true,
        trackFrustrations: true,
        defaultPrivacyLevel: 'allow',
    });

    datadogRum.startSessionReplayRecording();
});
