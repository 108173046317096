import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import Vue from 'vue';
import Vuelidate from 'vuelidate';

/**
 * https://vuelidate.js.org/
 */
export default defineNuxtPlugin(() => {
    Vue.use(Vuelidate);
});
