import { defineNuxtPlugin } from '@nuxtjs/composition-api';

/**
 *  https://nuxtjs.org/announcements/going-full-static/#preview-mode
 *  Because we are in full static mode enalbing preview requires a query
 *  parameter to be passed in to work in production mode.
 *  https://test.energysage.com/?preview=true
 */
export default defineNuxtPlugin(({ query, enablePreview }) => {
    if (query.preview && enablePreview) {
        enablePreview();
    }
});
